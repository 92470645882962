import { Flex, Text, Tokens } from "@accurx/design";
import { DateFormatOptions, DateHelpers } from "@accurx/shared";
import { useContent } from "../../context/ContentContext";
import { useOrganisation } from "../../context/OrganisationContext";
import { useAnswer } from "../../context/AnswerContext";
import { PATIENT_DETAILS_QUESTION_ID } from "../../constant/patientDetails";
import styled from "styled-components";

const StyledTextItems = styled(Text)`
    margin-bottom: ${Tokens.SIZES[1]};
`;

type ReceptionPatientDetailsProp = {
    header?: React.ReactNode;
    phoneNumber?: React.ReactNode;
};

export const ReceptionPatientDetails = ({
    header,
    phoneNumber,
}: ReceptionPatientDetailsProp): JSX.Element | null => {
    const { meta } = useContent();
    const { receptionPatientDetails } = useOrganisation();
    const { questionsWithAnswers } = useAnswer();

    const displayPhoneNumber = () => {
        return (
            questionsWithAnswers[PATIENT_DETAILS_QUESTION_ID.PHONE]?.answers ??
            receptionPatientDetails?.phoneNumber
        );
    };

    const displayPostCode = () => {
        return (
            questionsWithAnswers[PATIENT_DETAILS_QUESTION_ID.POSTCODE]
                ?.answers ?? receptionPatientDetails?.postCode
        );
    };

    const receptionMeta = meta.pages.reception;
    if (!receptionPatientDetails) return null;
    return (
        <>
            {header || (
                <StyledTextItems variant="label">
                    {receptionPatientDetails.surname},{" "}
                    {receptionPatientDetails.name}
                </StyledTextItems>
            )}
            <Flex gap="1">
                <Text variant="label">{receptionMeta.dateOfBirth}</Text>
                <Text>
                    {DateHelpers.formatTime(
                        receptionPatientDetails.dateOfBirth,
                        DateFormatOptions.DATE_SHORT_WITH_SLASH,
                    )}
                </Text>
            </Flex>
            {phoneNumber || (
                <Flex gap="1">
                    <Text variant="label">{receptionMeta.phoneNumber}</Text>
                    <Text>{displayPhoneNumber()}</Text>
                </Flex>
            )}

            {receptionPatientDetails.postCode && (
                <Flex gap="1">
                    <Text variant="label">{receptionMeta.postCode}</Text>
                    <Text>{displayPostCode()}</Text>
                </Flex>
            )}
        </>
    );
};
