import { Grid, Link, NhsButton, Text } from "@accurx/design";
import { useContent } from "../../../context/ContentContext";
import { useEffect, useState } from "react";
import { URL } from "../../../constant/url";
import { trackPatientClickedNhsLogin } from "../../../analytics/PatientInitiatedEvents";
import { useAnalytics } from "../../../context/AnalyticsContext";

export const NHSLoginButtonSection = (): JSX.Element => {
    const { meta } = useContent();
    const { baseAnalytics } = useAnalytics();

    const [returnUrl, setReturnUrl] = useState("");

    useEffect(() => {
        if (!global.window) return;
        setReturnUrl(
            `/api/OpenIdConnect/Authenticate?returnUrl=${global.window?.location.href}`,
        );
    }, [global.window]);

    const { nhsLogin } = meta.pages.landing;

    const trackLoginEvent = (): void => {
        if (!baseAnalytics) return;
        return trackPatientClickedNhsLogin(baseAnalytics);
    };

    return (
        <Grid gap="1">
            <Text as="h2" variant="label">
                {nhsLogin.title}
            </Text>

            <NhsButton href={returnUrl} onClick={trackLoginEvent}>
                {nhsLogin.button}
            </NhsButton>

            <Text>
                {nhsLogin.moreInformation.intro}{" "}
                <Link href={URL.NHS_LOGIN_HELP} openInNewTab theme="light">
                    <Link.Text text={nhsLogin.moreInformation.link} />
                    <Link.Icon />
                </Link>
            </Text>
        </Grid>
    );
};
