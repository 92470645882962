import { Card, Tokens } from "@accurx/design";
import styled from "styled-components";

export const ActionableCardList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${Tokens.SIZES["2"]};
    list-style: none;
    padding: 0;
`;

export const StyledHoveredIcon = styled.div`
    display: none;
`;
export const StyledUnhoveredIcon = styled.div`
    display: inline-block;
`;

export const StyledCard = styled(Card).attrs({
    spacing: 2,
    isElevated: false,
})``;

export const StyledDisabledCard = styled(StyledCard)`
    background-color: ${Tokens.COLOURS.greyscale.dishwater};
`;

export const StyledCardButton = styled.button`
    text-align: start;
    border: 0;
    border-radius: ${Tokens.SIZES[0.5]};
    background-color: transparent;
    width: 100%;
    padding: 0;

    &:hover ${StyledCard}, &:focus-within ${StyledCard} {
        background-color: ${Tokens.COLOURS.primary.blue[10]};
        cursor: pointer;
        border: 1px solid ${Tokens.COLOURS.primary.blue[100]};
    }

    &:hover ${StyledHoveredIcon}, &:focus-within ${StyledHoveredIcon} {
        display: inline-block;
    }

    &:hover ${StyledUnhoveredIcon}, &:focus-within ${StyledUnhoveredIcon} {
        display: none;
    }
`;
