import { Feedback, Text } from "@accurx/design";
import { MarkdownRenderer } from "../MarkdownRenderer";

type BannerProps = {
    title: string;
    body: string;
    subtext: string;
};

export const Banner = ({ title, body, subtext }: BannerProps) => {
    return (
        <Feedback colour="warning" title={title} titleAs="h2">
            {body && <Text variant="label">{body}</Text>}
            <MarkdownRenderer markdown={subtext} />
        </Feedback>
    );
};
