import { useRouter } from "next/router";
import { NHSLoginButtonSection } from "./NHSLoginButtonSection";
import { NHSLoginSuccess } from "./NHSLoginSuccess";
import { getProvidedPatientDetails } from "../../api/patient";
import { useEffect } from "react";
import { useOrganisation } from "../../context/OrganisationContext";

export const NHSLogin = (): JSX.Element => {
    const router = useRouter();
    const { providedPatientDetails, setProvidedPatientDetails } =
        useOrganisation();

    const fetchPatientDetails = async (): Promise<void> => {
        const response = await getProvidedPatientDetails();
        const hasPatientDetails = Object.values(response ?? {}).some(
            (property) => property,
        );

        if (!hasPatientDetails) return;
        setProvidedPatientDetails(response);
    };

    useEffect(() => {
        if (providedPatientDetails) return;

        const { isLoggedIn, requestType } = router.query;
        if (isLoggedIn !== "true" && typeof requestType !== "string") {
            return;
        }
        fetchPatientDetails();
    }, [router.query, providedPatientDetails]);

    return providedPatientDetails ? (
        <NHSLoginSuccess />
    ) : (
        <NHSLoginButtonSection />
    );
};
