import { Flex, Icon, Tokens, Text } from "@accurx/design";
import { PageHead } from "../components/shared/PageHead";
import styled from "styled-components";
import { StyledPaddingBreakPoints } from "../components/shared/CommonStyles.styles";

const StyledFlex = styled(Flex)`
    padding-top: ${Tokens.SIZES[3]};
`;

const StyledCentredTextWrapper = styled.div`
    text-align: center;
    padding: ${Tokens.SIZES[2]};
    ${StyledPaddingBreakPoints};
`;

export const MaintenanceSection = (): JSX.Element => {
    return (
        <>
            <PageHead text={"Scheduled Maintenance"} />
            <StyledFlex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Icon theme="Line" name="Cog" halo={{ colour: "greyscale" }} />
                <StyledCentredTextWrapper>
                    <Text as="h1" variant="subtitle">
                        Scheduled maintenance
                    </Text>
                    <Text>
                        This service is temporarily unavailable due to
                        maintenance. We appreciate your patience and assure you
                        that normal service will resume as soon as possible
                    </Text>
                </StyledCentredTextWrapper>
            </StyledFlex>
        </>
    );
};
