import { Feedback, Link, Text } from "@accurx/design";
import { useContent } from "../../../context/ContentContext";
import { URL } from "../../../constant/url";

export const NHSLoginSuccess = (): JSX.Element => {
    const { meta } = useContent();
    const { nhsLogin } = meta.pages.landing;

    return (
        <Feedback colour="success" title={nhsLogin.successTitle}>
            <Text>
                {nhsLogin.manageAccount.intro}{" "}
                <Link href={URL.NHS_MANAGE_ACCOUNT} openInNewTab theme="light">
                    <Link.Text text={nhsLogin.manageAccount.link} />
                    <Link.Icon />
                </Link>
            </Text>
        </Feedback>
    );
};
