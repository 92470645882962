import { Flex } from "@accurx/design";
import { Category, CategoryType } from "../../api/sections";
import { useMemo } from "react";
import { useRouter } from "next/router";
import { useOrganisation } from "../../context/OrganisationContext";
import { getPatientInitiatedRoute, PATHS } from "../../constant/paths";
import { ActionableCard, DisabledActionableCard } from "../ActionableCard";
import { useAnalytics } from "../../context/AnalyticsContext";
import {
    PatientTriagePanelClickProps,
    trackPatientInitiatedOutOfHoursLinkClicked,
    trackTriageFormRequestTypeClick,
} from "../../analytics/PatientInitiatedEvents";

type CategoriesProps = {
    categories: Record<string, Category>;
    selectCategoryId(categoryId: string): void;
};

export const Categories = ({
    categories,
    selectCategoryId,
}: CategoriesProps): JSX.Element => {
    const { organisationSettings } = useOrganisation();
    const { baseAnalytics } = useAnalytics();
    const router = useRouter();

    const categoryItems = Object.values(categories);

    const sortedCategories = useMemo(() => {
        return categoryItems.sort((a, b) => a.order - b.order);
    }, [categoryItems]);

    const trackFormRequest = (title: string): void => {
        if (!baseAnalytics) return;
        const analyticsProps: PatientTriagePanelClickProps = {
            ...baseAnalytics,
            requestType: title,
            panelClicked: title,
        };
        return trackTriageFormRequestTypeClick(analyticsProps);
    };

    const trackOutOfHoursCategories = (category: Category): void => {
        if (!baseAnalytics) return;
        return trackPatientInitiatedOutOfHoursLinkClicked({
            linkName: category.title,
            ...baseAnalytics,
        });
    };

    const onCategoryClick = (category: Category): void => {
        selectCategoryId(category.id);
        trackFormRequest(category.title);

        switch (category.type) {
            case CategoryType.Hyperlink:
                trackOutOfHoursCategories(category);
                window.open(category.hyperlink);
                return;
            case CategoryType.Markdown:
                router.push(
                    getPatientInitiatedRoute(
                        organisationSettings?.organisationCode,
                        `/${encodeURI(category.title.toLowerCase())}`,
                        router.query,
                    ),
                );
                return;
            case CategoryType.Questions:
            default:
                router.push(
                    getPatientInitiatedRoute(
                        organisationSettings?.organisationCode,
                        PATHS.confirmNotEmergency,
                        router.query,
                    ),
                );
                return;
        }
    };

    return (
        <Flex flexDirection="column" gap="2">
            {sortedCategories.map((category: Category) => {
                if (category.isEnabled) {
                    return (
                        <ActionableCard
                            label={category.title}
                            content={category.subtitle}
                            externalLink={category.hyperlink !== null}
                            onClick={() => onCategoryClick(category)}
                            key={category.id}
                        />
                    );
                }

                return (
                    <DisabledActionableCard
                        label={category.title}
                        content={category.subtitle}
                        key={category.id}
                    />
                );
            })}
        </Flex>
    );
};
