import { css } from "styled-components";
import { Tokens } from "@accurx/design";

export const StyledCommonPadding = css`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: 0 67px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: 0 96px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        padding: 0 150px;
    }
`;

export const StyledAuthCommonPadding = css`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: ${Tokens.SIZES[4]} 158px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: ${Tokens.SIZES[4]} 344px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        padding: ${Tokens.SIZES[4]} 445px;
    } ;
`;

export const StyledPaddingBreakPoints = css`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding: 0 158px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding: 0 344px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        padding: 0 445px;
    } ;
`;

export const StyledXLScreen = css`
    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        padding: ${Tokens.SIZES[4]} 0;
        max-width: 550px;
        margin: 0 auto;
    } ;
`;

export const StyledFooterSection = css`
    @media (min-width: ${Tokens.BREAKPOINTS.md}) {
        padding-left: 142px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.lg}) {
        padding-left: 328px;
    }

    @media (min-width: ${Tokens.BREAKPOINTS.xl}) {
        display: flex;
        justify-content: center;
        padding-right: 100px;
        padding: 0 100px 0 0;
    } ;
`;
