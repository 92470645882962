
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/patient-initiated/[organisationCode]",
      function () {
        return require("private-next-pages/patient-initiated/[organisationCode]/index.page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/patient-initiated/[organisationCode]"])
      });
    }
  