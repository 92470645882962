import { getApiEndpoint, httpClient } from "@accurx/shared";
import { ProvidedPatientDetails } from "./PatientDTOs";

const ENDPOINTS = {
    patient: "PatientDetails",
} as const;

export const getProvidedPatientDetails =
    async (): Promise<ProvidedPatientDetails | null> => {
        const response = await httpClient.getReturnJsonSafeAsync(
            getApiEndpoint({
                path: ENDPOINTS.patient,
            }),
        );

        return response?.result ?? null;
    };
