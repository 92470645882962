import Head from "next/head";

type TitleHeaderProps = {
    text: string;
};

export const PageHead = ({ text }: TitleHeaderProps) => {
    return (
        <Head>
            <title>{text} - Accurx Patient Portal</title>
            <meta name="description" content={text} />
            <link rel="icon" href="/favicon.png" />
        </Head>
    );
};
