import { ReactNode } from "react";

import { Icon, Text, Flex, Item } from "@accurx/design";

import {
    StyledCard,
    StyledCardButton,
    StyledDisabledCard,
    StyledHoveredIcon,
    StyledUnhoveredIcon,
} from "./ActionableCard.styles";

type EnabledActionableCardProps = {
    onClick?: () => void;
    externalLink?: boolean;
} & ActionableCardBodyProps;

type ActionableCardBodyProps = {
    label: string;
    content: string | null;
    children?: ReactNode;
};

const ActionableCardBody = ({
    label,
    content,
    children,
}: ActionableCardBodyProps) => {
    return (
        <Flex alignItems="center" justifyContent="space-between">
            <Item>
                <Flex flexDirection="column" gap="0.5">
                    <Text as="span" variant="label" skinny>
                        <Flex>{label}</Flex>
                    </Text>

                    {content && (
                        <Text as="span" skinny>
                            {content}
                        </Text>
                    )}
                </Flex>
            </Item>
            {children}
        </Flex>
    );
};

export const DisabledActionableCard = ({
    label,
    content,
}: ActionableCardBodyProps) => {
    return (
        <StyledDisabledCard>
            <ActionableCardBody label={label} content={content} />
        </StyledDisabledCard>
    );
};

export const ActionableCard = ({
    onClick,
    label,
    content,
    externalLink,
}: EnabledActionableCardProps) => {
    const iconName = externalLink ? "OpenWindow" : "ArrowTail";
    const rotation = externalLink ? "down" : "right";

    return (
        <StyledCardButton onClick={onClick} aria-label={label}>
            <StyledCard>
                <ActionableCardBody label={label} content={content}>
                    <Item>
                        <StyledHoveredIcon>
                            <Icon
                                name={iconName}
                                size={4}
                                rotation={rotation}
                                halo={{
                                    colour: "blue",
                                    luminosity: "low",
                                }}
                                props={{
                                    "aria-hidden": true,
                                    focusable: false,
                                }}
                            />
                        </StyledHoveredIcon>
                        <StyledUnhoveredIcon>
                            <Icon
                                name={iconName}
                                size={4}
                                rotation={rotation}
                                halo={{
                                    colour: "blue",
                                    luminosity: "high",
                                }}
                                props={{
                                    "aria-hidden": true,
                                    focusable: false,
                                }}
                            />
                        </StyledUnhoveredIcon>
                    </Item>
                </ActionableCardBody>
            </StyledCard>
        </StyledCardButton>
    );
};
