import { IncomingHttpHeaders } from "node:http";
import {
    apiGetResult,
    getUrlWithPatientInitiatedBackendBase,
} from "../../../../api/PatientPortalApiServer";
import { OrganisationSettings } from "./OrganisationDTOs";
import { getApiEndpoint } from "@accurx/shared";

const ENDPOINTS = {
    ORGANISATION_SETTINGS: "patientInitiated/v1/settings/:organisationCode",
} as const;

export const fetchOrganisationSettings = async (args: {
    organisationCode: string;
    token: string;
    requestHeaders: IncomingHttpHeaders;
}): Promise<{ organisationSettings: OrganisationSettings } | null> => {
    const { organisationCode, token, requestHeaders } = args;

    const { urlFinal } = getApiEndpoint({
        path: ENDPOINTS.ORGANISATION_SETTINGS,
        params: { organisationCode },
        query: `?token=${token}`,
    });

    const result = await apiGetResult(
        getUrlWithPatientInitiatedBackendBase(urlFinal),
        requestHeaders,
    );

    if (!result?.jsonResult) return null;
    return { organisationSettings: result.jsonResult };
};
