import { Text } from "@accurx/design";
import { Banner } from "../Banner";
import { Sections } from "../Sections";
import styled from "styled-components";
import { useContent } from "../../context/ContentContext";

const StyledHeaderText = styled(Text)`
    margin-bottom: 0;
`;

export const DefaultLandingPageSection = (): JSX.Element => {
    const { landingPageSettingsContent } = useContent();
    return (
        <>
            <StyledHeaderText forwardedAs="h1" variant="title">
                {landingPageSettingsContent.pageTitle}
            </StyledHeaderText>
            <Banner
                title={landingPageSettingsContent.bannerHeader}
                body={landingPageSettingsContent.bannerBody}
                subtext={landingPageSettingsContent.bannerSubtext}
            />
            <Sections />
        </>
    );
};
