import { useMemo } from "react";

import { Grid, Text } from "@accurx/design";

import { Categories } from "../Categories";
import { useContent } from "../../context/ContentContext";
import { useAnswer } from "../../context/AnswerContext";

export const Sections = (): JSX.Element => {
    const { sections } = useContent();
    const { setSelectedSectionId, setSelectedCategoryId } = useAnswer();

    const sortedSections = useMemo(() => {
        return sections.sort((a, b) => a.order - b.order);
    }, [sections]);

    const selectSectionCategoryIds = (
        sectionId: string,
        categoryId: string,
    ): void => {
        setSelectedSectionId(sectionId);
        setSelectedCategoryId(categoryId);
    };

    return (
        <Grid gap="2">
            {sortedSections
                .filter((section) => !!Object.keys(section.categories).length)
                .map((section) => (
                    <Grid as="section" gap="2" key={`section-${section.id}`}>
                        <Text as="h2" variant="subtitle">
                            {section.title}
                        </Text>
                        <Categories
                            categories={section.categories}
                            selectCategoryId={(categoryId) =>
                                selectSectionCategoryIds(section.id, categoryId)
                            }
                        />
                    </Grid>
                ))}
        </Grid>
    );
};
