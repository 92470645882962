import { NhsLoginType } from "../types";

export const getNhsLoginType = (
    arePatientDetailsProvided: boolean,
    isNhsApp: boolean,
): NhsLoginType => {
    if (isNhsApp) {
        return "nhsApp";
    } else {
        return arePatientDetailsProvided ? "nhsLogin" : null;
    }
};
